<template>
    <router-view></router-view>
</template>

<script>
export default{
     created() {
    },
    mounted(){
    },
    unmounted(){
    },
    beforeUnmount() {
    },
}
</script>