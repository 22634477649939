import { createRouter, createWebHistory } from 'vue-router';
//import Service from './service/Service';

const routes = [

    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/',
        name: 'welcome',
        component: () => import('./pages/DownLoad.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// router.beforeEach(function(to, from, next) {
//     // if(to.name!='login'){
//     //     var service = new Service();
//     //     if(!sessionStorage.getItem('user')){
//     //         var url='system/getlogindata.php';
//     //         service.sendHttp(url).then(res=>res.json()).then(data=>{
//     //             if(data.result=='success'){
//     //                 sessionStorage.setItem("user",JSON.stringify(data.user));
//     //                 sessionStorage.setItem("stores",JSON.stringify(data.stores));
//     //                 sessionStorage.setItem("permissions",JSON.stringify(data.permissions));
//     //                 service.changeComponent(data.permissions,router);
//     //                 next();
//     //             }
//     //             else{
//     //                 next({ name: 'login' });
//     //             }
//     //         });
//     //     }
//     //     else{
//     //         next();
//     //     }
//     // }
//     // else{
//     //     next();
//     // } 
// });

export default router;
